/*Reset*/
body, html{
	margin: 0;
	padding: 0;
	width: 100%;
	float: left;
	font-size: 100%;
	font-family: 'Montserrat Regular';
	color: white;
	background-color: #5991A9;
}
div, a, p, img, section, span, h1, h2, h3, input{
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}
@font-face {
    font-family: 'Montserrat Regular';
    src: url('assets/css/fonts/Montserrat-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

#header{
	z-index: 2000;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 5rem;
	float: left;
	background-color: #346F88;
	/*color: #C1E1F0;*/
	color: white;
	box-shadow: 2px 2px 10px #666;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
}

.menu_logo{
	height: 3rem;
	margin: 1rem;
	cursor: pointer;
}
#header_title{
	font-size: 3rem;
	letter-spacing: .5rem;
	text-transform: uppercase;
	display: flex;
	flex-direction: row;
	align-items: center;
}
#header_title img{
	height: 4rem;
}
.header_switcher{
	cursor: pointer; 
	height: 3rem; 
	margin: 1rem;
}
.header_switcher:hover{
	height: 3rem; 
}

#menu{
	z-index: 2000;
	position: fixed;
	top: 5rem;
	left:0;
	width: 20%;
	background-color: #215369;
	padding-top: 2rem;
	transform: translate(-100%);
	min-height: calc(100vh - 5rem);
}
#menu_clicker{
	z-index: 1900;
	position: fixed;
	top: 0;
	left:0;
	width: 100%;
	opacity: .2;
	background-color:  #215369;
	padding-top: 2rem;
	min-height: 100vh;
}
#menu a{
	float: left;
	height: 4rem;
	width: 100%;
	font-size: 1.4rem;
	text-align: center;
	padding: 1rem 0;
	cursor: pointer;
	text-transform: uppercase;
}
.menu_cerrar{
	font-size: 1rem!important;
	position: absolute;
	left: 0;
	bottom: 0;
}
#menu a:hover{
	background-color: #95C1D5;
	color: #215369;
}
#background{
	z-index: 0;
	position: fixed;
	top: 5rem;
	width: 100%;
	height: calc(100vh - 5rem);
	/*background-image: url(assets/img/icono.png);*/
	background-repeat: no-repeat;
	background-position: center;
	background-size: 40vw;
	opacity: .3;
}
#content{
	position: relative;
	z-index: 1000;
	left: 0;
	top: 5rem;
	width: 100%;
}
.content_window{
	float: left;
	width: 100%;
	position: relative;

}
.center_column{
	position: relative;
	width: 60vw;
	float: left;
	margin: 0 20vw;
	padding-top: 5rem;
}
/*Inicio*/
.welcome{
	float: right;
	width: 100%;
	text-align: center;
	margin-top: 30vh;
	font-size: 4rem;
}
/*Federados*/
.search_input{
	float: left;
	width: 100%;
	font-size: 1rem;
	height: 2.3rem;
	border-radius: 1.5rem;
	outline: none;
	padding: 0rem 1rem;
	font-family: 'Montserrat Regular';	
	border: transparent;
}
.tarjeta_s_container{
	float: left;
	margin: .5rem 0;
	width: 100%;
	height: auto;
	background-color: white;
	border-radius: 1.3rem;
	border-radius: 1.3rem;
}
.tarjeta_s{
	float: left;
	position: relative;
	width: 100%;
	height: 2.3rem;
	background-color: white;
	border-radius: 1.3rem;
	color: black;
}
.tarjeta_s_licencia{
	position: absolute;
	top: 0;
	left: 0;
	display: inline-block;
	height: 2.3rem;
	line-height: 2.3rem;
	padding-left: 1rem;

}
.tarjeta_s_nombre{
	display: inline-block;
	height: 2.3rem;
	width: 40vw;
	line-height: 2.3rem;
	padding-left: 4rem;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow-x: hidden;
}
.button{
	display: inline-block;
	height: 2.3rem;
	line-height: 2.3rem;
	border-radius: 2.3rem;
	background-color: #C1E1F0;
	color: #215369;
	padding: 0 2rem;
	cursor: pointer;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow-x: hidden;
	font-size: 1rem;
}
.button:hover{
	background-color: #95C1D5;

}
.button.dark{
	background-color: #346F88;
	color: white;
}
.button.red{
	background-color: #be5370;
	color: white;
}
.button.red:hover{
	background-color: #88344b;
}
.button.dark:hover{
	background-color: #215369;

}
.tarjeta_s_button{
	font-size: 1rem;
	position: absolute;
	top: 0;
	right: 0;
}
.tarjeta_s_footer{
	float: left;
	width: 100%;
	height: auto;
	position: relative;
	background-color: white;
	border-bottom-left-radius: 1.3rem;
	border-bottom-right-radius: 1.3rem;
	padding: 1rem;
	color: black;
}
.tarjeta_s_footer.spaced{
	padding-bottom: 4rem;
}
.tarjeta_s_footer.see{
	display: block;
}
.tarjeta_s_footer.unsee{
	display: none;
}
.tarjeta_s_layer{
	float: left;
	margin: .5rem;
}

.float_button{
	z-index: 600;
	position: fixed;
	bottom: 2rem;
	right: 2rem;
	width: 4rem;
	cursor: pointer;
	border-radius: 50%;
	box-shadow: 2px 2px 10px #666;
}
.float_button:hover{
	box-shadow: 2px 2px 15px #666;
}
.reafiliar_button{
	bottom: 8rem;
}
/*Configuracion*/
.tarjeta_configuracion{
	width: 100%;
	background-color:#C1E1F0;
	color: #215369;
	border-radius: 1rem;
	padding: 1rem;
}

/*add user*/
#column_tab{
	z-index: 1500;
	position: absolute;
	top: 0;
	left: 0;
	background-color: #C1E1F0;
	color: #215369;
	width: 100%;
	margin-top: 2rem;
	padding: 2rem 2rem 3rem 2rem;
	border-radius: 1.3rem;
}
#column_tab::before{
	content: '';
	position: absolute;
	width: 2rem;
	height: 2rem;
	bottom: -2rem;
}
.tab_header{
	float: left;
	width: 100%;
}
.tab_cancel{
	position: absolute;
	bottom: 0;
	left: 0;
	margin: 1rem;
	cursor: pointer;
	width: 100%;
}
.tab_input_button{
	float: left;
	margin-left: 1rem;
}
.tab_input_container{
	float: left;
	margin: 1.5rem .3rem;
}
.tab_input_layout{
	float: left;
	width: 100%;
	font-size: 1.4rem;
	margin: 0 0 .7rem 0;
}
.tab_input{
	float: left;
	font-size: 1rem;
	border-radius: 1.5rem;
	outline: none;
	padding: .5rem;
	border: solid .15rem #215369;
	font-family: 'Montserrat Regular';	
}
.tab_input.text{
	border: none;
	color: black;
}
.tab_input_checkbox{
	float: left;
	font-size: 1.3rem;
	border-radius: 1.5rem;
	padding: .5rem;
	margin: .2rem;
	border: solid .15rem #215369;
	font-family: 'Montserrat Regular';
	cursor: pointer;
}
.tab_input_checkbox.on{
	border-color: white;
	background-color: white;
}
.tab_input_checkbox.on:hover{
	border-color: #215369;
}
.tab_input_checkbox:hover{
	border-color: white;
}
/*Resources*/

.right_corner{
	position: absolute;
	right: 0;
	bottom: 0;
	margin: .6rem;
}
.left_corner{
	position: absolute;
	left: 0;
	bottom: 0;
	margin: .6rem;
}
.floating_left{
	float: left;
}
.floating_right{
	float:right;
}
.spacer{
	margin-top: 2rem;
}
.centrify{
	width: 100%;
	float: left;
	display: flex;
	justify-content: space-around;
	align-items: center;
	
}
.full_width{
	width: 100%;
}
.translate{
	transform: translateY(-2.3rem);
}
/*Pantalla de login*/
#logger{
	width: 100%;
	min-height: 100vh;
	float: left;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}
#logger_column{
	color: white;
	width: 600px;
	display: flex;
	flex-direction: column;
	opacity: .6;
	align-items: center;
	background-color: #215369;
	animation: appear 1s forwards;
}
.logger_input{
	outline: none;
	margin: .5rem 0;
	font-size: 2rem;
	padding: .5rem;
	width: 95%;
	font-family: 'Montserrat Regular';
}
.logger_button{
	font-size: 1.7rem;
	padding: .5rem 1rem;
	width: 95%;
	text-align: center;
	margin: .5rem 0 1rem 0 ;
	background-color: #C1E1F0;
	color: #215369;
	cursor: pointer;
}
.logger_button:hover{
	background-color: #a6c1ce;
}
.logger_title{
	font-size: 3rem;
	margin: 2rem 0 3rem 0;
}


@media(max-width: 900px){
	#menu{
		width: 30%;
	}
}
@media(max-width: 600px){
	#logger_column{
		width: 100%;
	}
	#menu{
		width: 100%;
	}
	.menu_cerrar{
		
		bottom: 4rem;
	}
	#header_title img{
		height: 3rem;
	}
	#header_title{
		font-size: 10vw;
		letter-spacing: 1vw;
	}
	#background{
		background-size: 60vw;
	}
	.center_column{
		width: 96%;
		margin: 0 2%;
	}
	.tarjeta_s_nombre{
		width: 60vw;
	}
	.centrify.notmobile{
		display: block;
	}
}

@keyframes expandir{
	0%{
		transform: translate(-100%);
	}
	100%{
		transform: translate(0);
	}
}
@keyframes contraer{
	0%{
		transform: translate(0);
	}
	100%{
		transform: translate(-100%);
	}
}
@keyframes appear{
	from{
		opacity: 0;
		transform: translateY(2rem);
	}
	to{
		opacity: 1;
		transform: translateY(0rem);
	}

}